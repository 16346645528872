<template>
  <b-sidebar
    id="element-sidebar"
    :visible="isToggleSidebar"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => toggleSidebar(val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          {{ titleForm }}
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>
      <!-- Form -->
      <b-form
        class="p-2"
        @reset.prevent="resetForm"
      >
        <h6
          class="text-info"
        >
          <feather-icon icon="AlertCircleIcon" />
          <span class="align-middle ml-25">Datos Generales</span>
        </h6>

        <hr style="border-top: 2px solid #00cfe8;margin-bottom: 1rem !important;margin-top: 0 !important;">

        <b-form-group
          label="Descripción"
          label-for="descripcion"
        >
          <b-form-input
            id="descripcion"
            v-model="dataRegister.descripcion"
            autofocus
            trim
            placeholder=""
          />
        </b-form-group>

        <b-form-group
          label="Detalla otros?"
          label-for="especifico"
        >
          <v-select
            id="especifico"
            v-model="othersSel"
            :reduce="m => m.value"
            label="text"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="others"
            placeholder="Seleccione"
          />
        </b-form-group>

        <b-form-group
          label="Icono"
          label-for="icono"
        >
          <b-form-input
            id="icono"
            v-model="dataRegister.icono"
            autofocus
            trim
            placeholder=""
          />
        </b-form-group>

        <!-- Form Actions -->
        <div class="d-flex mt-2">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-2"
            type="button"
            @click="saveForm"
          >
            Guardar
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            type="button"
            variant="outline-secondary"
            @click="hide"
          >
            Cancelar
          </b-button>
        </div>

      </b-form>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BButton,
} from 'bootstrap-vue'
import { ref } from '@vue/composition-api'
import { required } from '@validations'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import store from '@/store'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BButton,
    vSelect,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isToggleSidebar',
    event: 'update:is-toggle-sidebar',
  },
  props: {
    isToggleSidebar: {
      type: Boolean,
      required: true,
    },
    dataEdit: {
      type: Object,
      required: false,
      default: null,
    },
    formType: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      required,
    }
  },
  setup(props, { emit }) {
    const titleForm = ref('')
    const dataRegister = ref({})
    const others = ref([
      {
        text: 'Sí',
        value: 'S',
      },
      {
        text: 'No',
        value: 'N',
      },
    ])
    const othersSel = ref({ text: 'No', value: 'N' })

    const resetForm = () => {
      dataRegister.value = {}
      othersSel.value = { text: 'No', value: 'N' }
    }

    const saveForm = async () => {
      let service = 'catalogo/MODALIDAD_TURISMO_CREATE'
      dataRegister.value.especifico = othersSel.value

      if (props.formType === 'edit') {
        service = 'catalogo/MODALIDAD_TURISMO_UPDATE'
      }
      await store.dispatch(service, dataRegister.value)
        .then(response => {
          emit('refetch-data', response)
          emit('update:is-toggle-sidebar', false)
        })
        .catch(error => {
          emit('error-data', error)
          // emit('update:is-toggle-sidebar', false)
        })
    }

    const toggleSidebar = val => {
      resetForm()
      emit('update:is-toggle-sidebar', val)
      if (props.formType === 'new') {
        titleForm.value = 'Agregar Modalidad de Turismo'
      }
      if (props.formType === 'edit') {
        dataRegister.value = props.dataEdit
        othersSel.value = others.value.find(item => item.value === props.dataEdit.especifico)
        titleForm.value = 'Editar Modalidad de Turismo'
      }
    }

    return {
      resetForm,
      dataRegister,
      saveForm,
      toggleSidebar,
      titleForm,
      others,
      othersSel,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';

#element-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}

.vs__selected-options {
  flex-wrap: nowrap;
  max-width: calc(100% - 41px);
}

.vs__selected {
  display: block;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 100%;
  overflow: hidden;
}

.vs__search {
  position: absolute;
}

.vs--open .vs__search {
  position: static;
}

.vs__dropdown-option {
  white-space: normal;
}
</style>
